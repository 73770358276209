import React from 'react'

const DownloadIcon = ({ darkMode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={20}
            viewBox="0 0 21 20"
            fill="none"
        >
            <path
                d="M18 11.875V16.25C18 16.5815 17.8683 16.8995 17.6339 17.1339C17.3995 17.3683 17.0815 17.5 16.75 17.5H4.25C3.91848 17.5 3.60054 17.3683 3.36612 17.1339C3.1317 16.8995 3 16.5815 3 16.25V11.875C3 11.7092 3.06585 11.5503 3.18306 11.4331C3.30027 11.3158 3.45924 11.25 3.625 11.25C3.79076 11.25 3.94973 11.3158 4.06694 11.4331C4.18415 11.5503 4.25 11.7092 4.25 11.875V16.25H16.75V11.875C16.75 11.7092 16.8158 11.5503 16.9331 11.4331C17.0503 11.3158 17.2092 11.25 17.375 11.25C17.5408 11.25 17.6997 11.3158 17.8169 11.4331C17.9342 11.5503 18 11.7092 18 11.875ZM10.0578 12.3172C10.1159 12.3753 10.1848 12.4214 10.2607 12.4529C10.3365 12.4843 10.4179 12.5005 10.5 12.5005C10.5821 12.5005 10.6635 12.4843 10.7393 12.4529C10.8152 12.4214 10.8841 12.3753 10.9422 12.3172L14.0672 9.19219C14.1253 9.13412 14.1713 9.06518 14.2027 8.98931C14.2342 8.91344 14.2503 8.83212 14.2503 8.75C14.2503 8.66788 14.2342 8.58656 14.2027 8.51069C14.1713 8.43482 14.1253 8.36588 14.0672 8.30781C14.0091 8.24974 13.9402 8.20368 13.8643 8.17225C13.7884 8.14083 13.7071 8.12465 13.625 8.12465C13.5429 8.12465 13.4616 8.14083 13.3857 8.17225C13.3098 8.20368 13.2409 8.24974 13.1828 8.30781L11.125 10.3664V3.125C11.125 2.95924 11.0592 2.80027 10.9419 2.68306C10.8247 2.56585 10.6658 2.5 10.5 2.5C10.3342 2.5 10.1753 2.56585 10.0581 2.68306C9.94085 2.80027 9.875 2.95924 9.875 3.125V10.3664L7.81719 8.30781C7.69991 8.19054 7.54085 8.12465 7.375 8.12465C7.20915 8.12465 7.05009 8.19054 6.93281 8.30781C6.81554 8.42509 6.74965 8.58415 6.74965 8.75C6.74965 8.91585 6.81554 9.07491 6.93281 9.19219L10.0578 12.3172Z"
                fill= {darkMode ? '#ECDCFF' : '#270057'}
            />
        </svg>

    )
}

export default DownloadIcon