import React from 'react'

const EyeIcon = ({ darkMode, step }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={24}
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M23.3103 11.6962C23.2775 11.6222 22.4834 9.86062 20.7181 8.09531C18.3659 5.74312 15.395 4.5 12.125 4.5C8.85499 4.5 5.88405 5.74312 3.53187 8.09531C1.76655 9.86062 0.968741 11.625 0.939679 11.6962C0.897035 11.7922 0.875 11.896 0.875 12.0009C0.875 12.1059 0.897035 12.2097 0.939679 12.3056C0.972491 12.3797 1.76655 14.1403 3.53187 15.9056C5.88405 18.2569 8.85499 19.5 12.125 19.5C15.395 19.5 18.3659 18.2569 20.7181 15.9056C22.4834 14.1403 23.2775 12.3797 23.3103 12.3056C23.3529 12.2097 23.375 12.1059 23.375 12.0009C23.375 11.896 23.3529 11.7922 23.3103 11.6962ZM12.125 18C9.23937 18 6.71843 16.9509 4.63155 14.8828C3.77528 14.0313 3.04679 13.0603 2.46874 12C3.04664 10.9396 3.77514 9.9686 4.63155 9.11719C6.71843 7.04906 9.23937 6 12.125 6C15.0106 6 17.5316 7.04906 19.6184 9.11719C20.4764 9.9684 21.2065 10.9394 21.7859 12C21.11 13.2619 18.1653 18 12.125 18ZM12.125 7.5C11.235 7.5 10.3649 7.76392 9.62493 8.25839C8.8849 8.75285 8.30813 9.45566 7.96753 10.2779C7.62694 11.1002 7.53782 12.005 7.71146 12.8779C7.88509 13.7508 8.31367 14.5526 8.94301 15.182C9.57235 15.8113 10.3742 16.2399 11.2471 16.4135C12.12 16.5872 13.0248 16.4981 13.8471 16.1575C14.6693 15.8169 15.3721 15.2401 15.8666 14.5001C16.3611 13.76 16.625 12.89 16.625 12C16.6238 10.8069 16.1492 9.66303 15.3056 8.81939C14.462 7.97575 13.3181 7.50124 12.125 7.5ZM12.125 15C11.5316 15 10.9516 14.8241 10.4583 14.4944C9.96493 14.1648 9.58042 13.6962 9.35335 13.1481C9.12629 12.5999 9.06688 11.9967 9.18264 11.4147C9.29839 10.8328 9.58411 10.2982 10.0037 9.87868C10.4232 9.45912 10.9578 9.1734 11.5397 9.05764C12.1217 8.94189 12.7249 9.0013 13.273 9.22836C13.8212 9.45542 14.2898 9.83994 14.6194 10.3333C14.949 10.8266 15.125 11.4067 15.125 12C15.125 12.7956 14.8089 13.5587 14.2463 14.1213C13.6837 14.6839 12.9206 15 12.125 15Z"
                fill={
                    darkMode && step === 4
                        ? '#ECDCFF'
                        : step === 4
                            ? '#270057'
                            : step > 4
                                ? '#191C1C'
                                : darkMode
                                    ? '#545858'
                                    : '#a9adab'
                }
            />
        </svg>
    )
}

export default EyeIcon