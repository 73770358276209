import React from 'react'

const WifiIcon = ({darkMode}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={13}
            viewBox="0 0 18 13"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.77052 3.10398C11.2576 3.10408 13.6496 4.02616 15.4521 5.67964C15.5879 5.8073 15.8048 5.80569 15.9385 5.67603L17.236 4.41257C17.3037 4.34681 17.3414 4.25773 17.3409 4.16505C17.3403 4.07237 17.3015 3.98372 17.233 3.91873C12.502 -0.455983 5.03829 -0.455983 0.307275 3.91873C0.238743 3.98368 0.199859 4.07229 0.199227 4.16497C0.198595 4.25766 0.236267 4.34676 0.303908 4.41257L1.60177 5.67603C1.73537 5.80588 1.95249 5.8075 2.08814 5.67964C3.89088 4.02606 6.28317 3.10397 8.77052 3.10398ZM8.76717 7.32425C10.1245 7.32417 11.4334 7.83591 12.4395 8.76004C12.5756 8.89119 12.7899 8.88835 12.9226 8.75363L14.2099 7.43432C14.2777 7.36512 14.3153 7.27124 14.3143 7.17369C14.3133 7.07614 14.2738 6.98306 14.2047 6.91527C11.1408 4.02442 6.3961 4.02442 3.33227 6.91527C3.26306 6.98306 3.22357 7.07619 3.22266 7.17377C3.22176 7.27135 3.2595 7.36522 3.32744 7.43432L4.61435 8.75363C4.747 8.88835 4.96136 8.89119 5.09745 8.76004C6.1029 7.83652 7.41074 7.32482 8.76717 7.32425ZM11.2916 10.1178C11.2935 10.2232 11.2565 10.3247 11.1892 10.3985L9.01249 12.8533C8.94868 12.9254 8.86169 12.966 8.77092 12.966C8.68015 12.966 8.59316 12.9254 8.52935 12.8533L6.35232 10.3985C6.28507 10.3247 6.24808 10.2231 6.25007 10.1177C6.25206 10.0124 6.29287 9.9126 6.36286 9.84199C7.75296 8.52809 9.78888 8.52809 11.179 9.84199C11.2489 9.91266 11.2897 10.0125 11.2916 10.1178Z"
                fill={darkMode ? 'white' : 'black'}
            />
        </svg>
    )
}

export default WifiIcon