import React from 'react';

const MoonIcon = ({ darkMode, isNotCardPreviewPage, isCardPreviewPage }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21.8943 13.334C21.7981 13.2376 21.6774 13.1692 21.5453 13.1362C21.4132 13.1032 21.2745 13.1067 21.1443 13.1465C19.7142 13.5789 18.1936 13.6151 16.7445 13.2514C15.2955 12.8877 13.9722 12.1377 12.9158 11.0813C11.8594 10.0249 11.1093 8.7016 10.7456 7.25254C10.3819 5.80347 10.4182 4.28288 10.8505 2.85279C10.8906 2.72248 10.8945 2.5837 10.8616 2.45137C10.8288 2.31903 10.7605 2.19816 10.6641 2.10175C10.5676 2.00534 10.4468 1.93703 10.3144 1.90418C10.1821 1.87132 10.0433 1.87517 9.91302 1.91529C7.93586 2.52096 6.20009 3.73478 4.95271 5.38404C3.86185 6.83234 3.19645 8.55614 3.03127 10.3618C2.86609 12.1674 3.20768 13.9833 4.01765 15.6055C4.82763 17.2277 6.0739 18.5919 7.61646 19.5448C9.15901 20.4978 10.9367 21.0017 12.7499 21C14.8652 21.0065 16.9243 20.3188 18.6111 19.0425C20.2604 17.7951 21.4742 16.0593 22.0799 14.0822C22.1196 13.9523 22.1232 13.8142 22.0905 13.6824C22.0579 13.5507 21.99 13.4303 21.8943 13.334ZM17.7093 17.8444C16.1207 19.0408 14.1534 19.6232 12.1695 19.4843C10.1857 19.3454 8.31871 18.4945 6.9124 17.0884C5.50609 15.6822 4.65507 13.8153 4.51599 11.8314C4.37692 9.84753 4.95916 7.88019 6.15552 6.29154C6.93496 5.26222 7.94265 4.42784 9.09927 3.85404C9.03338 4.31644 9.00018 4.78291 8.9999 5.24998C9.00263 7.835 10.0307 10.3134 11.8586 12.1413C13.6865 13.9691 16.1649 14.9972 18.7499 15C19.2179 14.9998 19.6853 14.9666 20.1486 14.9006C19.5743 16.0574 18.7393 17.0651 17.7093 17.8444Z"
      fill={
        darkMode && isNotCardPreviewPage
          ? 'white'
          : isCardPreviewPage
          ? 'white'
          : '#191C1C'
      }
    />
  </svg>
);

export default MoonIcon;
