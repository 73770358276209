import React from 'react'

const CellularIcon = ({darkMode}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={13}
            viewBox="0 0 20 13"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7 1.68211C19.7 1.04907 19.2224 0.535889 18.6333 0.535889H17.5667C16.9776 0.535889 16.5 1.04907 16.5 1.68211V11.6161C16.5 12.2491 16.9776 12.7623 17.5667 12.7623H18.6333C19.2224 12.7623 19.7 12.2491 19.7 11.6161V1.68211ZM12.2659 2.98117H13.3326C13.9217 2.98117 14.3992 3.50667 14.3992 4.15491V11.5886C14.3992 12.2368 13.9217 12.7623 13.3326 12.7623H12.2659C11.6768 12.7623 11.1992 12.2368 11.1992 11.5886V4.15491C11.1992 3.50667 11.6768 2.98117 12.2659 2.98117ZM7.93411 5.63022H6.86745C6.27834 5.63022 5.80078 6.16241 5.80078 6.8189V11.5736C5.80078 12.2301 6.27834 12.7623 6.86745 12.7623H7.93411C8.52322 12.7623 9.00078 12.2301 9.00078 11.5736V6.8189C9.00078 6.16241 8.52322 5.63022 7.93411 5.63022ZM2.63333 8.07551H1.56667C0.977563 8.07551 0.5 8.6001 0.5 9.24721V11.5906C0.5 12.2377 0.977563 12.7623 1.56667 12.7623H2.63333C3.22244 12.7623 3.7 12.2377 3.7 11.5906V9.24721C3.7 8.6001 3.22244 8.07551 2.63333 8.07551Z"
                fill={darkMode ? 'white' : 'black'}

            />
        </svg>
    )
}

export default CellularIcon