import React from 'react'

const BrandLogo = ({ darkMode, isCardPreviewPage }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={106}
            height={20}
            viewBox="0 0 106 20"
            fill="none"
            className='w-24 md:w-full'
        >
            <path
                d="M11.7925 12.922H5.82289V19.9874H0V0H13.9566C20.5314 0 22.4387 3.29542 22.4387 6.34009C22.4387 8.56987 21.2833 11.1847 17.8263 12.2504L23.1907 19.9964H16.6434L11.7925 12.922ZM5.82289 9.29522H13.5439C16.3683 9.29522 17.2027 7.91616 17.2027 6.51024C17.2027 5.13118 16.3683 3.80585 13.5715 3.80585H5.82289V9.29522Z"
                fill={`${darkMode ? 'White' :
                    isCardPreviewPage ?
                        'white' : '#191C1C'
                    }`}
            />
            <path
                d="M48.5165 16.0204V19.9874H27.6366V3.8147e-05H48.3147V3.96707H33.4595V7.99679H47.8196V11.7131H33.4687V16.0204H48.5165Z"
                fill={`${darkMode ? 'White' :
                    isCardPreviewPage ?
                        'white' : '#191C1C'
                    }`}
            />
            <path
                d="M71.532 15.9667H60.1154L58.2173 19.9964H51.9634L62.1878 3.8147e-05H69.4596L79.6657 19.9964H73.4393L71.532 15.9667ZM61.8394 12.331H69.7988L65.7916 3.40291L61.8394 12.331Z"
                fill={`${darkMode ? 'White' :
                    isCardPreviewPage ?
                        'white' : '#191C1C'
                    }`}
            />
            <path
                d="M106 6.6446C106 10.9251 102.598 13.6563 97.3162 13.6563H89.3567V19.9964H83.5338V3.8147e-05H97.4629C102.8 3.8147e-05 106 2.50742 106 6.6446ZM100.801 6.78788C100.801 4.87152 99.4161 3.80589 96.9677 3.80589H89.3567V9.85942H96.931C99.3886 9.85942 100.801 8.7311 100.801 6.78788Z"
                fill={`${darkMode ? 'White' :
                    isCardPreviewPage ?
                        'white' : '#191C1C'
                    }`}
            />
        </svg>
    )
}

export default BrandLogo