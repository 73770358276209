import React from 'react'

const CubeIcon = ({ darkMode, step }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={24}
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M21.845 6.20146L13.595 1.68739C13.3746 1.5656 13.1268 1.50171 12.875 1.50171C12.6232 1.50171 12.3754 1.5656 12.155 1.68739L3.905 6.20333C3.6694 6.33224 3.47272 6.52205 3.33552 6.75292C3.19831 6.98379 3.12561 7.24727 3.125 7.51583V16.4821C3.12561 16.7506 3.19831 17.0141 3.33552 17.245C3.47272 17.4759 3.6694 17.6657 3.905 17.7946L12.155 22.3105C12.3754 22.4323 12.6232 22.4962 12.875 22.4962C13.1268 22.4962 13.3746 22.4323 13.595 22.3105L21.845 17.7946C22.0806 17.6657 22.2773 17.4759 22.4145 17.245C22.5517 17.0141 22.6244 16.7506 22.625 16.4821V7.51677C22.6249 7.24773 22.5524 6.98366 22.4152 6.75225C22.278 6.52084 22.081 6.3306 21.845 6.20146ZM12.875 2.99989L20.4069 7.12489L12.875 11.2499L5.34312 7.12489L12.875 2.99989ZM4.625 8.4374L12.125 12.5418V20.5846L4.625 16.483V8.4374ZM13.625 20.5846V12.5455L21.125 8.4374V16.4793L13.625 20.5846Z"
                fill={
                    darkMode && step === 1
                        ? '#ECDCFF'
                        : darkMode && step > 1
                            ? '#ECDCFF'
                            : step === 1
                                ? '#270057'
                                : step > 1
                                    ? '#191C1C'
                                    : darkMode
                                        ? '#545858'
                                        : '#a9adab'
                }
            />
        </svg>
    )
}

export default CubeIcon