import React from 'react'

const CrossIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            className=' cursor-pointer'
        >
            <path
                d="M14.6597 13.4655C14.8182 13.624 14.9072 13.839 14.9072 14.0631C14.9072 14.2873 14.8182 14.5023 14.6597 14.6608C14.5012 14.8193 14.2862 14.9084 14.062 14.9084C13.8378 14.9084 13.6229 14.8193 13.4644 14.6608L9.00021 10.1953L4.53467 14.6594C4.37616 14.8179 4.16118 14.9069 3.93701 14.9069C3.71285 14.9069 3.49786 14.8179 3.33935 14.6594C3.18085 14.5009 3.0918 14.2859 3.0918 14.0617C3.0918 13.8376 3.18085 13.6226 3.33935 13.4641L7.8049 8.99994L3.34076 4.53439C3.18225 4.37588 3.0932 4.1609 3.0932 3.93674C3.0932 3.71257 3.18225 3.49759 3.34076 3.33908C3.49927 3.18057 3.71425 3.09152 3.93842 3.09152C4.16258 3.09152 4.37756 3.18057 4.53607 3.33908L9.00021 7.80463L13.4658 3.33838C13.6243 3.17987 13.8393 3.09082 14.0634 3.09082C14.2876 3.09082 14.5026 3.17987 14.6611 3.33838C14.8196 3.49689 14.9086 3.71187 14.9086 3.93603C14.9086 4.1602 14.8196 4.37518 14.6611 4.53369L10.1955 8.99994L14.6597 13.4655Z"
                fill="#8E9190"
            />
        </svg>
    )
}

export default CrossIcon