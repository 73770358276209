import React from 'react'
import { useDarkMode } from '../../../globalStates/DarkModeContext';

export const ElementsHint = () => {
  const { darkMode } = useDarkMode();

  return (
    <div className={`tooltip py-3 px-4 ${darkMode ? 'dark-mode' : ''} `}>
      <h3 className='textColor text-base font-medium leading-5 mb-3'>💡 Design tip</h3>
      <p className='textColor text-sm font-normal leading-5'>
        Add your logo to the card. You can also select the logo size.
      </p>
      <p className='textColor text-sm font-normal leading-5 my-5'>
        Add your app icon, used for notifications in digital wallet apps. (You can skip this if you don't have digital wallet apps)            </p>
      <p className='textColor text-sm font-normal leading-5'>
        <span className=' font-medium'>Note:</span>
        check the color contrast between the card background, Visa Brand Mark, and Text color. <a href='https://www.notion.so/reap/CaaS-Digital-Card-Design-Guides-35e6409848c84bdea6531ecca8b4d459?pvs=4' rel="noreferrer" target='_blank' className={` ${darkMode ? 'color8' : 'color9'} `}>Read more</a>
      </p>
    </div>
  )
}

export default ElementsHint;


