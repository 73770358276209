import React from 'react'

const ImageIcon = ({ darkMode, step }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={24}
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M20.375 3.75H3.875C3.47718 3.75 3.09564 3.90804 2.81434 4.18934C2.53304 4.47064 2.375 4.85218 2.375 5.25V18.75C2.375 19.1478 2.53304 19.5294 2.81434 19.8107C3.09564 20.092 3.47718 20.25 3.875 20.25H20.375C20.7728 20.25 21.1544 20.092 21.4357 19.8107C21.717 19.5294 21.875 19.1478 21.875 18.75V5.25C21.875 4.85218 21.717 4.47064 21.4357 4.18934C21.1544 3.90804 20.7728 3.75 20.375 3.75ZM20.375 5.25V14.8828L17.9309 12.4397C17.7916 12.3004 17.6263 12.1898 17.4443 12.1144C17.2622 12.039 17.0672 12.0002 16.8702 12.0002C16.6731 12.0002 16.4781 12.039 16.2961 12.1144C16.114 12.1898 15.9487 12.3004 15.8094 12.4397L13.9344 14.3147L9.80938 10.1897C9.5281 9.9086 9.14672 9.7507 8.74906 9.7507C8.35141 9.7507 7.97003 9.9086 7.68875 10.1897L3.875 14.0034V5.25H20.375ZM3.875 16.125L8.75 11.25L16.25 18.75H3.875V16.125ZM20.375 18.75H18.3716L14.9966 15.375L16.8716 13.5L20.375 17.0044V18.75ZM13.625 9.375C13.625 9.1525 13.691 8.93499 13.8146 8.74998C13.9382 8.56498 14.1139 8.42078 14.3195 8.33564C14.525 8.25049 14.7512 8.22821 14.9695 8.27162C15.1877 8.31502 15.3882 8.42217 15.5455 8.5795C15.7028 8.73684 15.81 8.93729 15.8534 9.15552C15.8968 9.37375 15.8745 9.59995 15.7894 9.80552C15.7042 10.0111 15.56 10.1868 15.375 10.3104C15.19 10.434 14.9725 10.5 14.75 10.5C14.4516 10.5 14.1655 10.3815 13.9545 10.1705C13.7435 9.95952 13.625 9.67337 13.625 9.375Z"
                fill={
                    darkMode && step === 2
                        ? '#ECDCFF'
                        : darkMode && step > 2
                            ? '#ECDCFF'
                            : step === 2
                                ? '#270057'
                                : step > 2
                                    ? '#191C1C'
                                    : darkMode
                                        ? '#545858'
                                        : '#a9adab'
                }
            />
        </svg>
    )
}

export default ImageIcon